@import 'assets/styles/variables';
@import 'assets/styles/fonts';

.footer {
  width: 100%;
  height: 238px;
  background-color: var(--black);
  color: var(--white);

  align-items: center;
  padding: 0 111px;

  @media (max-width: $lg) {
    height: auto;
    padding: 20px 100px;
  }

  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 70%;

    @media (max-width: $lg) {
      height: auto;
      align-items: center;
    }

    .link {
      @include setFont('regular', 18);
      text-decoration: none;
      color: var(--white);
    }

    .logo {
      max-width: 200px;
      height: auto;
      margin-bottom: 10px;

      @media (max-width: $lg) {
        margin-bottom: 20px;
      }
    }
  }

  .copyright {
    text-align: center;

    @media (max-width: $lg) {
      margin: 20px 0px;
    }
  }

  .thanksForVisiting {
    margin-bottom: 10px;
    @include setFont('regular', 18);
    @media (max-width: $lg) {
      display: flex;
      justify-content: center;
    }
  }

  .socialMedia {
    display: grid;
    grid-template-columns: 20px 20px 20px 20px;
    grid-template-rows: 20px 20px 20px 20px;
    column-gap: 60px;

    @media (max-width: $lg) {
      display: flex;
      column-gap: 20px;
      justify-content: center;
    }

    .icon {
      border: 2px solid var(--white);
      border-radius: 50%;
      min-width: 60px !important;
      min-height: 60px !important;
      height: 60px !important;

      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
        fill: var(--white);
      }
    }
  }
}

body[dir='rtl'] {
  .footer {
    .socialMedia {
      .icon {
        svg {
          transform: scale(1, 1);
        }
      }
    }
  }
}
