@import 'assets/styles/fonts';
@import 'assets/styles/colors';

* {
  font-display: swap;
}
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: 'Tajawal-Regular', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Tajawal-Black';
  src: url('./assets/fonts/Tajawal-Black.ttf');
}

@font-face {
  font-family: 'Tajawal-Bold';
  src: url('./assets/fonts/Tajawal-Bold.ttf');
}

@font-face {
  font-family: 'Tajawal-ExtraBold';
  src: url('./assets/fonts/Tajawal-ExtraBold.ttf');
}

@font-face {
  font-family: 'Tajawal-ExtraLight';
  src: url('./assets/fonts/Tajawal-ExtraLight.ttf');
}

@font-face {
  font-family: 'Tajawal-Light';
  src: url('./assets/fonts/Tajawal-Light.ttf');
}

@font-face {
  font-family: 'Tajawal-Medium';
  src: url('./assets/fonts/Tajawal-Medium.ttf');
}

@font-face {
  font-family: 'Tajawal-Regular';
  src: url('./assets/fonts/Tajawal-Regular.ttf');
}

body[dir='rtl'] {
  text-align: right;
  div.no-rotate,
  svg:not(.no-rotate),
  img:not(.no-rotate) {
    transform: scaleX(-1);
  }
}
