:root {
  --primary-color: #6ec1e4;
  --secondary-color: #54595f;
  --text: #7a7a7a;
  --accent: #61ce70;
  --navy-blue: #4054b2;
  --success-green: #23a455;
  --black: #000;
  --light-black: #0c0c0c;
  --white: #fff;
  --active-link: #46c0db;
  --contact-form: #383838;
  --light-red: #e61930;
  --background-bottom: #212c71;
  --background-top: #16192c;
}
