@import 'assets/styles/colors';

.menuButton {
  margin-top: 20px;
  text-align: right;
  width: max-content;

  svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
    &:hover {
      g > path {
        stroke: var(--active-link);
        transition: stroke 0.2s ease-in;
      }
    }
  }
}

.sidebar {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px !important;
  background-color: var(--black);

  -webkit-animation: slideIn 0.6s forwards;
  -moz-animation: slideIn 0.6s forwards;
  animation: slideIn 0.6s forwards;

  .closeWrapper {
    flex-direction: row-reverse;
    margin-top: 20px;
    .closeIcon {
      text-align: end;
    }
  }

  .languageSwitcher {
    margin: 10px 0px;
    border-bottom: 1px solid var(--white);
    padding: 5px 0px;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
    svg {
      margin-inline-end: 10px;
      height: 25px;
      width: 25px;
    }
  }

  .navButtons {
    margin: 10px 0px;
    border-bottom: 1px solid var(--white);
    padding: 5px 0px;
  }

  @-webkit-keyframes slideIn {
    0% {
      right: -50%;
    }
    100% {
      right: 0;
    }
  }
  @-moz-keyframes slideIn {
    0% {
      right: -50%;
    }
    100% {
      right: 0;
    }
  }
  @keyframes slideIn {
    0% {
      right: -50%;
    }
    100% {
      right: 0;
    }
  }
}

.exiting {
  right: -50%;
  -webkit-animation: slideOut 0.6s forwards;
  -moz-animation: slideOut 0.6s forwards;
  animation: slideOut 0.6s forwards;

  @-webkit-keyframes slideOut {
    0% {
      right: 0;
    }
    100% {
      right: -50%;
    }
  }
  @-moz-keyframes slideOut {
    0% {
      right: 0;
    }
    100% {
      right: -50%;
    }
  }
  @keyframes slideOut {
    0% {
      right: 0;
    }
    100% {
      right: -50%;
    }
  }
}

body[dir='rtl'] {
  .menuButton {
    text-align: left;
  }

  .sidebar {
    z-index: 10;
    margin-right: auto;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;

    -webkit-animation: slideInRTL 0.6s forwards;
    -moz-animation: slideInRTL 0.6s forwards;
    animation: slideInRTL 0.6s forwards;

    @-webkit-keyframes slideInRTL {
      0% {
        left: -50%;
      }
      100% {
        margin-right: auto;
      }
    }
    @-moz-keyframes slideInRTL {
      0% {
        left: -50%;
      }
      100% {
        margin-right: auto;
      }
    }
    @keyframes slideInRTL {
      0% {
        left: -50%;
      }
      100% {
        margin-right: auto;
      }
    }
  }

  .exiting {
    margin-right: auto;
    left: -50%;
    -webkit-animation: slideOutRTL 0.6s forwards;
    -moz-animation: slideOutRTL 0.6s forwards;
    animation: slideOutRTL 0.6s forwards;

    @-webkit-keyframes slideOutRTL {
      0% {
        left: 0;
        margin-right: auto;
      }
      100% {
        left: -50%;
      }
    }
    @-moz-keyframes slideOutRTL {
      0% {
        left: 0;
        margin-right: auto;
      }
      100% {
        left: -50%;
      }
    }
    @keyframes slideOutRTL {
      0% {
        left: 0;
        margin-right: auto;
      }
      100% {
        left: -50%;
      }
    }
  }
}
