@import 'assets/styles/colors';
@import 'assets/styles/fonts';

.navWrapper {
  text-align: center;
  .navButton {
    text-transform: uppercase;
    text-decoration: none;
    color: var(--white);
    @include setFont('regular', 18);

    &:hover {
      color: var(--active-link);
      transition: color 0.2s ease-out;
    }
  }
}
