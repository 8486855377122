@import 'assets/styles/colors';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';

.background {
  background-image: linear-gradient(var(--background-top), var(--background-bottom));
  .header {
    position: fixed;
    width: 100%;
    background-color: var(--black);
    color: var(--text);
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 74px;
    padding: 10px;
    z-index: 9;

    .logoContainer {
      margin-inline-start: 80px;
      .logo {
        width: 100px;
        height: 24px;
      }

      @media (max-width: $md) {
        margin-bottom: 5px;
      }
    }

    .linkContainer {
      width: 850px !important;

      .languageSwitcher {
        width: 300px !important;
      }

      .link {
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--white);
        @include setFont('regular', 18);

        &:hover {
          color: var(--active-link);
          transition: color 0.2s ease-out;
        }
      }
    }
  }
}
