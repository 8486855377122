.navigationLoader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--black);
  color: var(--white);

  img {
    margin-inline-start: 10px;

    //animation start
    -webkit-animation: spinning 3s infinite;
    -moz-animation: spinning 3s infinite;
    animation: spinning 3s infinite;

    @-webkit-keyframes spinning {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-moz-keyframes spinning {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes spinning {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    //animation end
  }
  .text {
    margin-top: 15px;
  }
}
