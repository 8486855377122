@import '~bootstrap/scss/bootstrap';
@import 'assets/styles/bootstrap-override.scss';
@import 'assets/styles/variables';

body,
html {
  width: 100% !important;
  height: 100% !important;
}

input,
textarea {
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

.errorBorder {
  border: 1px solid var(--light-red) !important;
}

.text-danger {
  color: var(--light-red);
}

.carousel__slider-tray--horizontal {
  height: 80%;
}

.carousel__inner-slide {
  margin-left: 10px;
}

.carousel,
.carousel__slider,
.carousel__slider-tray-wrapper {
  height: 100%;
}
